// src/pages/Colors.js

import React from 'react';
import CardSelector from '../components/CardSelector';

function Colors() {
  return (
    <div>
      <h1>Selector de Cartas</h1>
      <CardSelector />
    </div>
  );
}

export default Colors;