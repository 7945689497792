// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Container } from 'react-bootstrap';

import Home from './pages/Home';
import About from './pages/About';
import Experience from './pages/Experience';
import SkillsAccordion from './pages/Skills';
import Contact from './pages/Contact';
import Colors from './pages/Colors';

function App() {
  return (
    <Router>
      <Routes>
        {/* Rutas sin Navbar */}
        <Route path="/colores" element={<Colors />} />

        {/* Rutas con Navbar */}
        <Route
          path="*"
          element={
            <>
              <Navbar bg="light" expand="lg">
                <Container>
                  <Navbar.Brand as={Link} to="/">Mi Portafolio</Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                      <Nav.Link as={Link} to="/about">Sobre Mí</Nav.Link>
                      <Nav.Link as={Link} to="/experience">Experiencia</Nav.Link>
                      <Nav.Link as={Link} to="/skills">Habilidades</Nav.Link>
                      <Nav.Link as={Link} to="/contact">Contacto</Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
              <Container className="mt-4">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/experience" element={<Experience />} />
                  <Route path="/skills" element={<SkillsAccordion />} />
                  <Route path="/contact" element={<Contact />} />
                </Routes>
              </Container>
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
