import React from 'react';
import { Container, Row, Col, Card, Button, Accordion } from 'react-bootstrap';

// Datos de productos organizados en categorías y subcategorías
const productCategories = [
    {
      "category": "Desarrollo Web",
      "subCategories": [
        {
          "subCategory": "Desarrollo de Blogs",
          "items": [
            {
              "title": "Web Blog con WordPress",
              "description": "Diseña y lanza blogs atractivos y funcionales con WordPress.",
              "price": "$1,200,000",
              "image": "src/assets/images/web_blog.png",
              "link": "#"
            },
            {
              "title": "Blog con Joomla",
              "description": "Crea blogs dinámicos y personalizables con Joomla.",
              "price": "$1,000,000",
              "image": "src/assets/images/joomla_blog.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "E-commerce",
          "items": [
            {
              "title": "Tienda Online con WooCommerce",
              "description": "Desarrolla tiendas en línea con WooCommerce para WordPress.",
              "price": "$1,800,000",
              "image": "src/assets/images/woocommerce.png",
              "link": "#"
            },
            {
              "title": "E-commerce con Magento",
              "description": "Construye soluciones de comercio electrónico con Magento.",
              "price": "$2,500,000",
              "image": "src/assets/images/magento.png",
              "link": "#"
            }
          ]
        }
      ]
    },
    {
      "category": "Aplicaciones Móviles",
      "subCategories": [
        {
          "subCategory": "Aplicaciones Nativas",
          "items": [
            {
              "title": "Aplicaciones para iOS",
              "description": "Desarrollo de aplicaciones móviles nativas para iOS.",
              "price": "$2,000,000",
              "image": "src/assets/images/ios_app.png",
              "link": "#"
            },
            {
              "title": "Aplicaciones para Android",
              "description": "Desarrollo de aplicaciones móviles nativas para Android.",
              "price": "$2,000,000",
              "image": "src/assets/images/android_app.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Aplicaciones Híbridas",
          "items": [
            {
              "title": "Aplicaciones con React Native",
              "description": "Desarrollo de aplicaciones híbridas usando React Native.",
              "price": "$2,500,000",
              "image": "src/assets/images/react_native.png",
              "link": "#"
            },
            {
              "title": "Aplicaciones con Flutter",
              "description": "Desarrollo de aplicaciones híbridas usando Flutter.",
              "price": "$2,500,000",
              "image": "src/assets/images/flutter.png",
              "link": "#"
            }
          ]
        }
      ]
    },
    {
      "category": "Sistemas y Automatización",
      "subCategories": [
        {
          "subCategory": "Automatización de Procesos",
          "items": [
            {
              "title": "Automatización de Tareas Repetitivas",
              "description": "Automatización de procesos repetitivos para mejorar la eficiencia.",
              "price": "$1,200,000",
              "image": "src/assets/images/automatizacion_tareas.png",
              "link": "#"
            },
            {
              "title": "Automatización de Flujos de Trabajo",
              "description": "Automatización de flujos de trabajo para optimizar operaciones.",
              "price": "$1,500,000",
              "image": "src/assets/images/automatizacion_flujos.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Desarrollo de Sistemas",
          "items": [
            {
              "title": "Desarrollo de ERP",
              "description": "Desarrollo e implementación de sistemas ERP para empresas.",
              "price": "$3,000,000",
              "image": "src/assets/images/erp.png",
              "link": "#"
            },
            {
              "title": "Sistemas de Gestión de Proyectos",
              "description": "Desarrollo de sistemas para la gestión de proyectos y tareas.",
              "price": "$2,500,000",
              "image": "src/assets/images/gestion_proyectos.png",
              "link": "#"
            }
          ]
        }
      ]
    },
    {
      "category": "Diseño de Interfaces",
      "subCategories": [
        {
          "subCategory": "Investigación y Prototipos",
          "items": [
            {
              "title": "Investigación de Usuario",
              "description": "Estudio y análisis de usuarios para mejorar el diseño.",
              "price": "$1,000,000",
              "image": "src/assets/images/investigacion_usuario.png",
              "link": "#"
            },
            {
              "title": "Prototipos y Wireframes",
              "description": "Creación de prototipos y wireframes para el diseño de interfaces.",
              "price": "$1,200,000",
              "image": "src/assets/images/prototipos_wireframes.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Diseño Visual",
          "items": [
            {
              "title": "Diseño de Logotipos",
              "description": "Creación de logotipos únicos y profesionales.",
              "price": "$800,000",
              "image": "src/assets/images/diseno_logotipos.png",
              "link": "#"
            },
            {
              "title": "Diseño de Material Publicitario",
              "description": "Diseño de material publicitario como folletos y carteles.",
              "price": "$1,000,000",
              "image": "src/assets/images/material_publicitario.png",
              "link": "#"
            }
          ]
        }
      ]
    },
    {
      "category": "Desarrollo de Software a Medida",
      "subCategories": [
        {
          "subCategory": "Gestión Empresarial",
          "items": [
            {
              "title": "Aplicaciones de Gestión Empresarial",
              "description": "Desarrollo de aplicaciones para la gestión de empresas.",
              "price": "$3,000,000",
              "image": "src/assets/images/gestion_empresarial.png",
              "link": "#"
            },
            {
              "title": "Sistemas de CRM",
              "description": "Desarrollo de sistemas de gestión de relaciones con clientes.",
              "price": "$2,500,000",
              "image": "src/assets/images/crm.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Soluciones Personalizadas",
          "items": [
            {
              "title": "Soluciones a Medida",
              "description": "Desarrollo de software personalizado para necesidades específicas.",
              "price": "$3,500,000",
              "image": "src/assets/images/soluciones_medida.png",
              "link": "#"
            },
            {
              "title": "Integración de Sistemas",
              "description": "Integración de diferentes sistemas para una operación fluida.",
              "price": "$2,800,000",
              "image": "src/assets/images/integracion_sistemas.png",
              "link": "#"
            }
          ]
        }
      ]
    },
    {
      "category": "Automatización y DevOps",
      "subCategories": [
        {
          "subCategory": "Automatización",
          "items": [
            {
              "title": "Automatización con Ansible",
              "description": "Automatización de infraestructura usando Ansible.",
              "price": "$1,800,000",
              "image": "src/assets/images/ansible.png",
              "link": "#"
            },
            {
              "title": "Despliegue Continuo con Docker",
              "description": "Automatización de despliegues usando Docker.",
              "price": "$1,800,000",
              "image": "src/assets/images/docker.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Integración Continua",
          "items": [
            {
              "title": "Integración Continua con Jenkins",
              "description": "Implementación de integración continua usando Jenkins.",
              "price": "$1,500,000",
              "image": "src/assets/images/jenkins.png",
              "link": "#"
            }
          ]
        }
      ]
    },
    {
      "category": "Soporte y Mantenimiento",
      "subCategories": [
        {
          "subCategory": "Soporte Continuo",
          "items": [
            {
              "title": "Soporte Técnico Continuo",
              "description": "Soporte técnico continuo para mantenimiento de sistemas.",
              "price": "$1,000,000",
              "image": "src/assets/images/soporte_tecnico.png",
              "link": "#"
            },
            {
              "title": "Resolución de Problemas de Aplicaciones",
              "description": "Soporte para la resolución de problemas en aplicaciones.",
              "price": "$1,000,000",
              "image": "src/assets/images/problemas_aplicaciones.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Actualizaciones y Optimización",
          "items": [
            {
              "title": "Actualización de Sistemas",
              "description": "Actualización y mejora de sistemas existentes.",
              "price": "$1,200,000",
              "image": "src/assets/images/actualizacion_sistemas.png",
              "link": "#"
            },
            {
              "title": "Optimización de Rendimiento",
              "description": "Optimización de aplicaciones y sistemas para mejor rendimiento.",
              "price": "$1,500,000",
              "image": "src/assets/images/optimizacion_rendimiento.png",
              "link": "#"
            }
          ]
        }
      ]
    }
  ];
  
// Datos de servicios organizados en categorías y subcategorías
const serviceCategories = [
    {
      "category": "Marketing Digital",
      "subCategories": [
        {
          "subCategory": "Optimización SEO",
          "items": [
            {
              "title": "Optimización SEO",
              "description": "Mejora de la visibilidad en buscadores a través de técnicas SEO.",
              "price": "$1,200,000",
              "image": "src/assets/images/seo.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Publicidad en Google Ads",
          "items": [
            {
              "title": "Publicidad en Google Ads",
              "description": "Campañas publicitarias en Google Ads para atraer clientes.",
              "price": "$1,500,000",
              "image": "src/assets/images/google_ads.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Publicidad en Facebook e Instagram",
          "items": [
            {
              "title": "Publicidad en Facebook e Instagram",
              "description": "Campañas publicitarias en redes sociales para aumentar la visibilidad.",
              "price": "$1,500,000",
              "image": "src/assets/images/facebook_instagram.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Gestión de Redes Sociales",
          "items": [
            {
              "title": "Gestión de Redes Sociales",
              "description": "Administración y estrategia para redes sociales.",
              "price": "$1,200,000",
              "image": "src/assets/images/redes_sociales.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Campañas de Email Marketing",
          "items": [
            {
              "title": "Campañas de Email Marketing",
              "description": "Diseño y envío de campañas de email marketing efectivas.",
              "price": "$1,200,000",
              "image": "src/assets/images/email_marketing.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Automatización de Correos Electrónicos",
          "items": [
            {
              "title": "Automatización de Correos Electrónicos",
              "description": "Configuración de automatización para envíos de correos electrónicos.",
              "price": "$1,000,000",
              "image": "src/assets/images/automatizacion_correos.png",
              "link": "#"
            }
          ]
        }
      ]
    },
    {
      "category": "Consultoría Estratégica",
      "subCategories": [
        {
          "subCategory": "Planificación de Proyectos",
          "items": [
            {
              "title": "Planificación de Proyectos",
              "description": "Planificación y estrategia para la ejecución de proyectos empresariales.",
              "price": "$1,800,000",
              "image": "src/assets/images/planificacion_proyectos.png",
              "link": "#"
            }
          ]
        }
      ]
    },
    {
      "category": "Desarrollo de Contenidos",
      "subCategories": [
        {
          "subCategory": "Redacción de Contenidos Web",
          "items": [
            {
              "title": "Redacción de Contenidos Web",
              "description": "Creación de contenido textual para sitios web.",
              "price": "$1,000,000",
              "image": "src/assets/images/redaccion_contenidos.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Creación de Infografías",
          "items": [
            {
              "title": "Creación de Infografías",
              "description": "Diseño de infografías visualmente atractivas.",
              "price": "$800,000",
              "image": "src/assets/images/infografias.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Estrategias de Contenidos",
          "items": [
            {
              "title": "Estrategias de Contenidos",
              "description": "Desarrollo de estrategias de contenido para marketing.",
              "price": "$1,200,000",
              "image": "src/assets/images/estrategias_contenidos.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Gestión de Blogs",
          "items": [
            {
              "title": "Gestión de Blogs",
              "description": "Administración y actualización de blogs.",
              "price": "$1,000,000",
              "image": "src/assets/images/gestion_blogs.png",
              "link": "#"
            }
          ]
        }
      ]
    },
    {
      "category": "Gestión de Proyectos",
      "subCategories": [
        {
          "subCategory": "Implementación de Scrum",
          "items": [
            {
              "title": "Implementación de Scrum",
              "description": "Adopción del marco de trabajo Scrum para gestión ágil de proyectos.",
              "price": "$1,500,000",
              "image": "src/assets/images/scrum.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Implementación de Kanban",
          "items": [
            {
              "title": "Implementación de Kanban",
              "description": "Aplicación de Kanban para la gestión visual de tareas y proyectos.",
              "price": "$1,500,000",
              "image": "src/assets/images/kanban.png",
              "link": "#"
            }
          ]
        }
      ]
    },
    {
      "category": "Formación y Capacitación",
      "subCategories": [
        {
          "subCategory": "Curso de Desarrollo Web",
          "items": [
            {
              "title": "Curso de Desarrollo Web",
              "description": "Curso en línea sobre desarrollo web.",
              "price": "$1,500,000",
              "image": "src/assets/images/curso_desarrollo_web.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Curso de Marketing Digital",
          "items": [
            {
              "title": "Curso de Marketing Digital",
              "description": "Curso en línea sobre estrategias de marketing digital.",
              "price": "$1,500,000",
              "image": "src/assets/images/curso_marketing_digital.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Taller de UX/UI",
          "items": [
            {
              "title": "Taller de UX/UI",
              "description": "Taller para mejorar el diseño de experiencia de usuario y interfaces.",
              "price": "$1,200,000",
              "image": "src/assets/images/taller_ux_ui.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Seminario de Estrategia Digital",
          "items": [
            {
              "title": "Seminario de Estrategia Digital",
              "description": "Seminario sobre estrategias digitales para negocios.",
              "price": "$1,500,000",
              "image": "src/assets/images/seminario_estrategia.png",
              "link": "#"
            }
          ]
        }
      ]
    },
    {
      "category": "Soporte Técnico",
      "subCategories": [
        {
          "subCategory": "Reparación de Equipos",
          "items": [
            {
              "title": "Reparación de Equipos",
              "description": "Servicios de reparación y mantenimiento de equipos.",
              "price": "$1,000,000",
              "image": "src/assets/images/reparacion_equipos.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Actualización de Componentes",
          "items": [
            {
              "title": "Actualización de Componentes",
              "description": "Actualización y mejora de componentes de hardware.",
              "price": "$800,000",
              "image": "src/assets/images/actualizacion_componentes.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Resolución de Problemas de Software",
          "items": [
            {
              "title": "Resolución de Problemas de Software",
              "description": "Soporte para la resolución de problemas de software.",
              "price": "$1,000,000",
              "image": "src/assets/images/problemas_software.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Instalación y Configuración de Software",
          "items": [
            {
              "title": "Instalación y Configuración de Software",
              "description": "Servicios de instalación y configuración de software.",
              "price": "$800,000",
              "image": "src/assets/images/configuracion_software.png",
              "link": "#"
            }
          ]
        }
      ]
    },
    {
      "category": "Desarrollo de Estrategias",
      "subCategories": [
        {
          "subCategory": "Planificación de Campañas",
          "items": [
            {
              "title": "Planificación de Campañas",
              "description": "Desarrollo y planificación de campañas de marketing.",
              "price": "$1,500,000",
              "image": "src/assets/images/planificacion_campanas.png",
              "link": "#"
            }
          ]
        },
        {
          "subCategory": "Estrategias de Contenidos",
          "items": [
            {
              "title": "Estrategias de Contenidos",
              "description": "Diseño de estrategias para la creación de contenido.",
              "price": "$1,500,000",
              "image": "src/assets/images/estrategias_contenidos.png",
              "link": "#"
            }
          ]
        }
      ]
    }
  ];
  

  const Productos = () => {
    return (
      <Container>
        <h1 className="my-4 text-center">Productos y Servicios</h1>
  
        {/* Sección de Productos */}
        <Accordion defaultActiveKey={null} className="my-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Productos</Accordion.Header>
            <Accordion.Body>
              {productCategories.map((category, index) => (
                <Accordion key={index} defaultActiveKey={null} className="my-4">
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header>{category.category}</Accordion.Header>
                    <Accordion.Body>
                      {category.subCategories.map((subCategory, subIndex) => (
                        <div key={subIndex} className="mb-4">
                          <h4 className="text-center mb-4">{subCategory.subCategory}</h4>
                          <Row className="justify-content-center">
                            {subCategory.items.map((item, itemIndex) => (
                              <Col xs={12} sm={6} md={4} lg={3} key={itemIndex} className="mb-4 d-flex justify-content-center">
                                <Card className="h-100 shadow-sm border-light">
                                  <Card.Img
                                    variant="top"
                                    src={item.image}
                                    style={{
                                      height: '200px',
                                      objectFit: 'cover',
                                    }}
                                  />
                                  <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                    <Card.Text>{item.description}</Card.Text>
                                    <Button variant="primary" href={item.link}>Más Información</Button>
                                  </Card.Body>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
  
        {/* Sección de Servicios */}
        <Accordion defaultActiveKey={null} className="my-4">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Servicios</Accordion.Header>
            <Accordion.Body>
              {serviceCategories.map((category, index) => (
                <Accordion key={index} defaultActiveKey={null} className="my-4">
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header>{category.category}</Accordion.Header>
                    <Accordion.Body>
                      {category.subCategories.map((subCategory, subIndex) => (
                        <div key={subIndex} className="mb-4">
                          <h4 className="text-center mb-4">{subCategory.subCategory}</h4>
                          <Row className="justify-content-center">
                            {subCategory.items.map((item, itemIndex) => (
                              <Col xs={12} sm={6} md={4} lg={3} key={itemIndex} className="mb-4 d-flex justify-content-center">
                                <Card className="h-100 shadow-sm border-light">
                                  <Card.Img
                                    variant="top"
                                    src={item.image}
                                    style={{
                                      height: '200px',
                                      objectFit: 'cover',
                                    }}
                                  />
                                  <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                    <Card.Text>{item.description}</Card.Text>
                                    <Card.Text className="text-muted">{item.price}</Card.Text>
                                    <Button variant="primary" href={item.link}>Más Información</Button>
                                  </Card.Body>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    );
  };
  
  export default Productos;