// src/pages/Home.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import LenguajesCarrusel from '../components/LenguajesCarrusel'; 
import MarketingService from '../components/MarketingService';
import Productos from '../components/Productos';
const Home = () => {
  return (
    <Container>
      <Row className="text-center">
        <Col>
          <Card className="text-bg-light mb-3">
            <Card.Body>
              <Card.Title>Bienvenido a mi portafolio</Card.Title>
              <Card.Text>
                Soy un profesional apasionado por la tecnología y el desarrollo de software.
                Aquí encontrarás información sobre mi experiencia,  habilidades y más.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row><Productos /> </Row>
        

      <MarketingService />

      {/* Renderiza el componente LenguajesCarrusel aquí */}
      <LenguajesCarrusel />
    </Container>
  );
};

export default Home;
