// src/components/LenguajesCarrusel.js
import React from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';

// Importa las imágenes directamente
import newRelicImg from '../assets/images/new_relic.png';
import pythonDjangoImg from '../assets/images/python_django.png';
import javascriptImg from '../assets/images/javascript.png';
import cssImg from '../assets/images/css.png';
import htmlImg from '../assets/images/html.png';
import reactImg from '../assets/images/react.png';
import sqlImg from '../assets/images/sql.png';
import swaggerImg from '../assets/images/swagger.png';
import postmanImg from '../assets/images/postman.png';
import postgresImg from '../assets/images/postgres.png';

const lenguajes = [
  { src: newRelicImg, alt: 'New Relic', name: 'New Relic', description: 'Plataforma para monitoreo y gestión del rendimiento de aplicaciones.' },
  { src: pythonDjangoImg, alt: 'Python con Django', name: 'Python con Django', description: 'Lenguaje de programación y framework para desarrollo web.' },
  { src: javascriptImg, alt: 'JavaScript', name: 'JavaScript', description: 'Lenguaje de programación para crear interactividad en páginas web.' },
  { src: cssImg, alt: 'CSS', name: 'CSS', description: 'Lenguaje de estilos para describir la presentación de documentos HTML.' },
  { src: htmlImg, alt: 'HTML', name: 'HTML', description: 'Lenguaje de marcado para estructurar contenido en la web.' },
  { src: reactImg, alt: 'React', name: 'React', description: 'Biblioteca de JavaScript para construir interfaces de usuario interactivas.' },
  { src: sqlImg, alt: 'SQL', name: 'SQL', description: 'Lenguaje de consulta estructurado para gestionar bases de datos relacionales.' },
  { src: swaggerImg, alt: 'Swagger', name: 'Swagger', description: 'Herramienta para la documentación y prueba de APIs RESTful.' },
  { src: postmanImg, alt: 'Postman', name: 'Postman', description: 'Herramienta para pruebas de API que permite enviar solicitudes HTTP y evaluar respuestas.' },
  { src: postgresImg, alt: 'PostgreSQL', name: 'PostgreSQL', description: 'Sistema de gestión de bases de datos relacional y objeto.' },
];

const LenguajesCarrusel = () => {
  return (
    <Container className="my-4">
      <h2 className="text-center mb-4">Tecnologías y Herramientas</h2>
      <Carousel>
        {lenguajes.map((item, index) => (
          <Carousel.Item key={index}>
            <Row className="justify-content-center">
              <Col md={4} className="text-center">
                <img
                  src={item.src}
                  alt={item.alt}
                  className="d-block w-100 rounded"
                  style={{ maxHeight: '150px', objectFit: 'contain' }}
                />
                <div className="mt-3">
                  <h4 className="text-dark" style={{ fontSize: '26px', fontWeight: 'bold' }}>{item.name}</h4>
                  <p className="text-dark" style={{ fontSize: '20px', margin: '0', padding: '0' }}>{item.description}</p>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default LenguajesCarrusel;
