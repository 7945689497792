import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Contact = () => {
  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.target;
    const name = form.name.value;
    const email = form.email.value;
    const message = form.message.value;

    // Construye la URL mailto con los datos del formulario
    const mailtoUrl = `mailto:nduplat@gmail.com?subject=Mensaje de ${encodeURIComponent(name)}&body=${encodeURIComponent(message)}%0D%0A%0D%0AEnviado desde: ${encodeURIComponent(email)}`;

    // Abre el cliente de correo electrónico del usuario con la URL mailto
    window.location.href = mailtoUrl;
  };

  return (
    <Container className="my-5">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h2 className="text-center mb-4">Contacto</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" name="name" placeholder="Ingresa tu nombre" required />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" placeholder="Ingresa tu email" required />
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Mensaje</Form.Label>
              <Form.Control as="textarea" name="message" rows={4} placeholder="Escribe tu mensaje" required />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100 mb-3">
              Enviar
            </Button>
          </Form>
          <div className="text-center">
            <a href="https://wa.me/573508295133" target="_blank" rel="noopener noreferrer" className="btn btn-success">
              Contactar por WhatsApp
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
