import React from 'react';
import { Container, Row, Col, ListGroup, Card } from 'react-bootstrap';
import profileImage from '../assets/images/profile.jpg'; 

const About = () => {
  return (
    <Container className="my-5">
      <Row className="mb-4">
      <Col md={4} className="d-flex justify-content-center">
          <img 
            src={profileImage} 
            alt="Foto profesional" 
            className="img-fluid rounded-circle border" 
            style={{ maxWidth: '200px', maxHeight: '200px' }} 
          />
        </Col>
        <Col md={8}>
          <h2>Nicolás Duplat Velásquez</h2>
          <h4>Desarrollador de Software</h4>
          <p>
            Profesional con más de cinco años de experiencia en soporte técnico y desarrollo de software, especializado en la identificación de bugs, revisión de logs, control de calidad y documentación. He trabajado en entornos tecnológicos y gubernamentales, contribuyendo a proyectos como el sistema de Transmilenio, SIAPO, y en iniciativas del Instituto Geográfico Agustín Codazzi. Mi enfoque se basa en la precisión, innovación y colaboración multidisciplinaria. Actualmente, estoy profundizando mis conocimientos en Python con Django y Kotlin para desarrollo multiplataforma. Busco oportunidades para aplicar mis habilidades en entornos dinámicos y en constante evolución.
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Header as="h5">Contacto</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>Teléfono: 3508295133</ListGroup.Item>
                <ListGroup.Item>Email: nduplat@gmail.com</ListGroup.Item>
              
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Header as="h5">Idiomas</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>Español</ListGroup.Item>
                <ListGroup.Item>Inglés</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header as="h5">Educación</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>Técnico en Desarrollo de Software - Fundación Universitaria Politécnico Grancolombiano (2019)( En Proceso)</ListGroup.Item>
                <ListGroup.Item>Bachillerato Académico con Énfasis en Finanzas y Banca - Colegio Fundación Colombia (2006)</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header as="h5">Experiencia</Card.Header>
            <Card.Body>
              <h6>Desarrollador, Beta Tester, Documentador - DNI Developers</h6>
              <p>Proyecto: SIAPO - Sistema de Transmilenio (2018/2023)</p>
              <ul>
                <li>Contribuí en el diseño de la arquitectura del sistema SIAPO, asegurando su escalabilidad y rendimiento óptimo.</li>
                <li>Trabajé en estrecha colaboración con el cliente para entender y traducir sus necesidades en funcionalidades concretas.</li>
                <li>Participé en la identificación y elaboración de más de 100 casos de uso detallados.</li>
                <li>Creé manuales de usuario y manuales técnicos, incluyendo pruebas exhaustivas con herramientas como Swagger.</li>
              </ul>

              <h6>QA y Desarrollador de Proyectos Gubernamentales - DNI Developers</h6>
              <p>Proyecto: Hacienda de la Gobernación del Valle y la Secretaría de Turismo de Cali (2022-2023)</p>
              <ul>
                <li>Realicé tareas de control de calidad y documentación técnica en proyectos gubernamentales.</li>
                <li>Participé en la garantía de cumplimiento y funcionalidad mediante pruebas meticulosas.</li>
              </ul>

              <h6>QA y Documentador - Instituto Geográfico Agustín Codazzi</h6>
              <p>Proyectos Geográficos (2020/2021)</p>
              <ul>
                <li>Control de calidad en proyectos como el Diccionario Geográfico de Colombia y Colombia OT.</li>
                <li>Documentación técnica y funcional de los proyectos, asegurando precisión y funcionalidad.</li>
              </ul>

              <h6>Desarrollador Front End - Blue Dragon Motors CRM</h6>
              <p>Actualmente trabajando en el desarrollo de un CRM para un taller de motos con autenticación, usuarios, roles, CRUD services, Bootstrap, Django, y despliegue de base de datos en PostgreSQL.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header as="h5">Cursos y Masterclasses</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>CAD Ingeniería y Sistemas</ListGroup.Item>
                <ListGroup.Item>Curso en QA y Desarrollo de Proyectos Gubernamentales</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header as="h5">Referencias</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <strong>Patricia Umaña</strong> - Encargada de parte de Transmilenio
                  <br />
                  Teléfono: *******
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Diego Andrés Nieto Quintero</strong> - CEO-DNI Developers; Arquitecto de Software
                  <br />
                  Teléfono: 3168186394
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Hernando Vargas</strong> - Desarrollador FrontEnd
                  <br />
                  Teléfono: 3103295562
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
