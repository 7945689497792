import React from 'react';
import { Accordion, Container, Row, Col, Card } from 'react-bootstrap';
import LenguajesCarrusel from '../components/LenguajesCarrusel';
// Datos de habilidades
const softSkills = [
  {
    title: "Liderazgo Inspirador",
    description: "Capacidad para motivar e inspirar a los demás, guiando equipos hacia el cumplimiento de metas y visión compartida.",
    subSkills: [
      { title: "Inspiración de Equipo", description: "Motivar a los miembros del equipo para alcanzar objetivos comunes." },
      { title: "Visión Estratégica", description: "Establecer y comunicar una visión clara del futuro." }
    ]
  },
  {
    title: "Comunicación Avanzada",
    description: "Habilidad para transmitir ideas de manera efectiva y clara, tanto verbal como escrita, y para escuchar activamente.",
    subSkills: [
      { title: "Comunicación Verbal", description: "Expresar ideas de manera clara en conversaciones y presentaciones." },
      { title: "Comunicación Escrita", description: "Redactar documentos y correos electrónicos de manera efectiva y profesional." }
    ]
  },
  {
    title: "Gestión de Equipos",
    description: "Experiencia en coordinar y gestionar equipos, delegar tareas y apoyar el desarrollo de los miembros del equipo.",
    subSkills: [
      { title: "Delegación de Tareas", description: "Asignar tareas de manera efectiva para maximizar el rendimiento del equipo." },
      { title: "Desarrollo de Talento", description: "Identificar y fomentar el crecimiento de habilidades en los miembros del equipo." }
    ]
  },
  {
    title: "Resolución de Conflictos",
    description: "Capacidad para identificar y resolver conflictos de manera constructiva, manteniendo un ambiente de trabajo armonioso.",
    subSkills: [
      { title: "Mediación", description: "Actuar como intermediario para resolver disputas entre partes." },
      { title: "Negociación Constructiva", description: "Buscar soluciones que satisfagan a todas las partes involucradas." }
    ]
  },
  {
    title: "Pensamiento Estratégico",
    description: "Habilidad para planificar a largo plazo, anticipar problemas y oportunidades, y tomar decisiones informadas.",
    subSkills: [
      { title: "Planificación a Largo Plazo", description: "Desarrollar estrategias para alcanzar metas futuras." },
      { title: "Análisis de Oportunidades", description: "Identificar y evaluar oportunidades para mejorar la posición estratégica." }
    ]
  },
  {
    title: "Adaptabilidad",
    description: "Capacidad para ajustarse a cambios y nuevas circunstancias, gestionando la incertidumbre de manera efectiva.",
    subSkills: [
      { title: "Flexibilidad", description: "Ajustarse rápidamente a nuevas situaciones y desafíos." },
      { title: "Manejo de la Incertidumbre", description: "Gestionar la falta de certeza con confianza y eficacia." }
    ]
  },
  {
    title: "Empatía y Inteligencia Emocional",
    description: "Habilidad para entender y gestionar tanto las propias emociones como las de los demás, facilitando relaciones laborales positivas.",
    subSkills: [
      { title: "Reconocimiento de Emociones", description: "Identificar las emociones propias y de los demás." },
      { title: "Gestión Emocional", description: "Manejar las emociones de manera que no afecten negativamente a las interacciones laborales." }
    ]
  },
  {
    title: "Gestión del Tiempo y Prioridades",
    description: "Capacidad para organizar y priorizar tareas y responsabilidades para cumplir con los objetivos establecidos.",
    subSkills: [
      { title: "Organización de Tareas", description: "Establecer un plan para completar tareas de manera eficiente." },
      { title: "Priorización", description: "Identificar y enfocarse en las tareas más importantes." }
    ]
  },
  {
    title: "Desarrollo Personal Continuo",
    description: "Compromiso con el autoaprendizaje y la mejora continua, aplicando los principios y técnicas aprendidos en el entrenamiento.",
    subSkills: [
      { title: "Autoevaluación", description: "Evaluar regularmente el propio progreso y áreas de mejora." },
      { title: "Aprendizaje Continuo", description: "Participar en formaciones y actividades para adquirir nuevas habilidades." }
    ]
  },
  {
    title: "Negociación y Persuasión",
    description: "Habilidad para negociar acuerdos y persuadir a otros para lograr objetivos y resolver problemas.",
    subSkills: [
      { title: "Técnicas de Negociación", description: "Utilizar estrategias efectivas para llegar a acuerdos beneficiosos." },
      { title: "Persuasión Eficaz", description: "Influir en otros para que acepten ideas o propuestas." }
    ]
  }
];

const techSkills = [
  {
    title: "Postman",
    description: "Herramienta para pruebas de API que permite enviar solicitudes HTTP y evaluar respuestas.",
    subSkills: [
      { title: "Pruebas de Endpoints", description: "Enviar solicitudes y verificar respuestas de endpoints de API." },
      { title: "Automatización de Pruebas", description: "Crear colecciones de pruebas para automatizar la validación de API." }
    ]
  },
  {
    title: "Swagger",
    description: "Herramienta para la documentación y prueba de APIs RESTful.",
    subSkills: [
      { title: "Documentación de API", description: "Generar documentación interactiva para APIs usando Swagger." },
      { title: "Pruebas de API", description: "Probar y validar endpoints de API a través de la interfaz Swagger." }
    ]
  },
  {
    title: "SQL",
    description: "Lenguaje de consulta estructurado para gestionar bases de datos relacionales.",
    subSkills: [
      { title: "Consultas SQL", description: "Escribir y ejecutar consultas para recuperar y manipular datos." },
      { title: "Optimización de Consultas", description: "Optimizar consultas para mejorar el rendimiento de la base de datos." }
    ]
  },
  {
    title: "React",
    description: "Biblioteca de JavaScript para construir interfaces de usuario interactivas.",
    subSkills: [
      { title: "Componentes React", description: "Crear y gestionar componentes reutilizables en una aplicación React." },
      { title: "Estado y Props", description: "Manejar el estado y las propiedades para gestionar datos en la aplicación." }
    ]
  },
  {
    title: "HTML",
    description: "Lenguaje de marcado para estructurar contenido en la web.",
    subSkills: [
      { title: "Estructura de Página", description: "Definir la estructura de una página web usando etiquetas HTML." },
      { title: "Semántica HTML", description: "Utilizar etiquetas HTML semánticas para mejorar la accesibilidad y SEO." }
    ]
  },
  {
    title: "CSS",
    description: "Lenguaje de estilos para describir la presentación de documentos HTML.",
    subSkills: [
      { title: "Estilos en CSS", description: "Aplicar estilos a elementos HTML para diseñar una página web." },
      { title: "Diseño Responsivo", description: "Crear diseños que se adapten a diferentes tamaños de pantalla." }
    ]
  },
  {
    title: "JavaScript",
    description: "Lenguaje de programación para crear interactividad en páginas web.",
    subSkills: [
      { title: "Manipulación del DOM", description: "Modificar el contenido y la estructura de una página web dinámicamente." },
      { title: "Eventos JavaScript", description: "Manejar eventos para crear interacciones de usuario." }
    ]
  },
  {
    title: "Python con Django",
    description: "Lenguaje de programación y framework para desarrollo web.",
    subSkills: [
      { title: "Desarrollo con Django", description: "Crear aplicaciones web utilizando el framework Django." },
      { title: "Modelado de Datos", description: "Definir modelos y gestionar bases de datos con Django ORM." }
    ]
  },
  {
    title: "New Relic",
    description: "Plataforma para monitoreo y gestión del rendimiento de aplicaciones.",
    subSkills: [
      { title: "Monitoreo de Aplicaciones", description: "Rastrear y analizar el rendimiento de aplicaciones en tiempo real." },
      { title: "Alertas y Notificaciones", description: "Configurar alertas para eventos críticos en el rendimiento de aplicaciones." }
    ]
  },
  {
    title: "Despliegue y Documentación API",
    description: "Procesos para implementar aplicaciones y documentar APIs.",
    subSkills: [
      { title: "Despliegue en Servidores", description: "Implementar aplicaciones en servidores y entornos de producción." },
      { title: "Documentación de API", description: "Crear documentación técnica para APIs para facilitar su uso y mantenimiento." }
    ]
  },
  {
    title: "Desarrollador de Software",
    description: "Profesional en desarrollo de software con experiencia en diversas áreas tecnológicas.",
    subSkills: [
      { title: "Control de Calidad", description: "Garantizar la calidad del software mediante pruebas y revisiones." },
      { title: "Documentación Técnica", description: "Crear documentación detallada sobre el software y sus funcionalidades." }
    ]
  }
];

const SkillsAccordion = () => {
  return (
    <Container className="my-4">
      <h2 className="text-center mb-4">Mis Habilidades Con</h2>

      {/* Carrusel */}
      <LenguajesCarrusel />

      {/* Habilidades de Desarrollo */}
      <Accordion defaultActiveKey="0" className="my-4">
        <Accordion.Item eventKey="0" className="mb-3 shadow-sm">
          <Accordion.Header>
            <h3 className="mb-0">Habilidades de Desarrollo</h3>
          </Accordion.Header>
          <Accordion.Body>
            <Accordion defaultActiveKey="0">
              {techSkills.map((skill, index) => (
                <Accordion.Item eventKey={index.toString()} key={index} className="mb-3 shadow-sm">
                  <Accordion.Header>
                    <h4 className="mb-0">{skill.title}</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-3">{skill.description}</p>
                    {skill.subSkills.map((subSkill, subIndex) => (
                      <Row key={subIndex} className="mb-3">
                        <Col sm={12} md={6}>
                          <Card border="light" className="mb-2">
                            <Card.Body>
                              <Card.Title>{subSkill.title}</Card.Title>
                              <Card.Text>{subSkill.description}</Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>

        {/* Habilidades Blandas */}
        <Accordion.Item eventKey="1" className="mb-3 shadow-sm">
          <Accordion.Header>
            <h3 className="mb-0">Habilidades Blandas</h3>
          </Accordion.Header>
          <Accordion.Body>
            <Accordion defaultActiveKey="0">
              {softSkills.map((skill, index) => (
                <Accordion.Item eventKey={index.toString()} key={index} className="mb-3 shadow-sm">
                  <Accordion.Header>
                    <h4 className="mb-0">{skill.title}</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-3">{skill.description}</p>
                    {skill.subSkills.map((subSkill, subIndex) => (
                      <Row key={subIndex} className="mb-3">
                        <Col sm={12} md={6}>
                          <Card border="light" className="mb-2">
                            <Card.Body>
                              <Card.Title>{subSkill.title}</Card.Title>
                              <Card.Text>{subSkill.description}</Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default SkillsAccordion;