import React, { useState, useEffect } from 'react';
import './CardSelector.css';

const CardSelector = () => {
  const [selectedSuit, setSelectedSuit] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [assignedCards, setAssignedCards] = useState([]);
  const maxCards = 7;

  useEffect(() => {
    const updateUI = () => {
      document.getElementById('selectedCards').textContent = assignedCards.join(', ');
      document.getElementById('assignButton').disabled = assignedCards.length >= maxCards;
      document.getElementById('sendButton').disabled = assignedCards.length !== maxCards;
    };

    updateUI();
  }, [assignedCards, maxCards]);

  const handleSuitClick = (suit) => {
    setSelectedSuit(suit);
    document.querySelectorAll('.suit').forEach(s => s.classList.remove('selected'));
    document.getElementById(suit).classList.add('selected');
  };

  const handleValueClick = (value) => {
    setSelectedValue(value);
    document.querySelectorAll('.value').forEach(v => v.classList.remove('selected'));
    document.getElementById(value).classList.add('selected');
  };

  const handleAssignClick = () => {
    if (selectedSuit && selectedValue) {
      const card = `${selectedValue}${selectedSuit.charAt(0).toUpperCase()}`;
      if (!assignedCards.includes(card)) {
        setAssignedCards([...assignedCards, card]);
        setSelectedSuit(null);
        setSelectedValue(null);
        document.querySelectorAll('.suit').forEach(s => s.classList.remove('selected'));
        document.querySelectorAll('.value').forEach(v => v.classList.remove('selected'));
      } else {
        alert('Esta carta ya ha sido seleccionada. Por favor, elige otra.');
      }
    } else {
      alert('Por favor, selecciona una pinta y un valor antes de asignar.');
    }
  };

  const handleSendClick = () => {
    alert(`Cartas seleccionadas: ${assignedCards.join(', ')}`);
  };

  return (
    <div className="container">
      <div className="section suits">
        <div className="suits-grid">
          {[
            { id: 'spades', symbol: '♠' },
            { id: 'diamonds', symbol: '♦' },
            { id: 'clubs', symbol: '♣' },
            { id: 'hearts', symbol: '♥' }
          ].map(suit => (
            <div key={suit.id} className="suit" id={suit.id} onClick={() => handleSuitClick(suit.id)}>
              {suit.symbol}
            </div>
          ))}
        </div>
      </div>
      <div className="section values">
        <div className="values-grid">
          {['A', '2', '3', '4', '5'].map(value => (
            <div key={value} className="value" id={value} onClick={() => handleValueClick(value)}>
              {value}
            </div>
          ))}
          {['6', '7', '8', '9', '10'].map(value => (
            <div key={value} className="value" id={value} onClick={() => handleValueClick(value)}>
              {value}
            </div>
          ))}
          {['J', 'Q', 'K'].map(value => (
            <div key={value} className="value" id={value} onClick={() => handleValueClick(value)}>
              {value}
            </div>
          ))}
        </div>
      </div>
      <div className="section assign">
        <button id="assignButton" onClick={handleAssignClick}>Asignar</button>
        <button id="sendButton" onClick={handleSendClick} disabled={assignedCards.length !== maxCards}>Enviar</button>
        <div id="selectedCards"></div>
      </div>
    </div>
  );
};

export default CardSelector;
